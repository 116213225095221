<template>
	<Metier name="systemes-informatique-et-conduite" title="Systèmes informatiques et conduite" :main-menu="[{title: 'Contremaître principal systèmes de conduite', missions: ['Dirige une équipe technique', 'Recherche l\'efficacité optimale des systèmes de conduite en proposant des améliorations', 'S\'assure de la bonne application des procédures de travail', 'Gère et les dossiers de travaux qui lui sont confiés'], competences: ['Formation en contrôle industriel ou en électronique', 'Connaissance de la législation et des règles de sécurité, des procédures administratives', 'Connaissance des équipements et des procédés', 'Maîtrise des outils informatiques'], qualites: ['Leadership', 'Sang-Froid', 'Rigueur', 'Esprit d\'analyse']}, {title: 'Technicien Systèmes de Conduite', missions: ['Participe au maintien de la qualité des systèmes de conduite via l\'analyse des dysfonctionnements et la proposition d\'améliorations', 'Rédige les dossiers de développement et maintient la documentation à jour', 'Participe aux arrêts et aux tests'], competences: ['Formation en contrôle industriel, automatismes ou électronique', 'Connaissance de la législation et des règles de sécurité, des procédures administratives', 'Connaissance des équipements et des procédés', 'Maîtrise des outils informatiques'], qualites: ['Esprit de synthèse', 'Rigueur', 'Organisation']}, {title: 'Chargé d\'affaires Réseaux et Systèmes Informatiques', missions: ['Assure le suivi des configurations matérielles et logicielles des systèmes informatiques', 'Gère les réseaux de télécommunication, de contrôle d\'accès et de vidéosurveillance du site', 'Est le correspondant des constructeurs et éditeurs concernant le suivi et l\'évolution du matériel et des logiciels'], competences: ['Formation en informatique', 'Maîtrise des outils informatiques'], qualites: ['Sens du contact', 'Organisation', 'Capacité d\'adaptation']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
